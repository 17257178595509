import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
const SvgTesFilter = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M10.3359 0.667969H1.66927C0.933937 0.667969 0.335938 1.26597 0.335938 2.0013V3.50596C0.335938 3.86196 0.474644 4.19663 0.726644 4.44863L4.14062 7.86263C4.26596 7.98863 4.33594 8.15596 4.33594 8.33396V11.0006C4.33594 11.1053 4.38524 11.2046 4.46924 11.2673L7.13591 13.2673C7.19457 13.3113 7.2646 13.334 7.33594 13.334C7.3866 13.334 7.43794 13.3226 7.48527 13.2986C7.59794 13.242 7.66927 13.1266 7.66927 13.0006V8.33396C7.66927 8.15596 7.73858 7.98863 7.86458 7.86263L11.2786 4.44863C11.5306 4.19663 11.6693 3.86196 11.6693 3.50596V2.0013C11.6693 1.26597 11.0713 0.667969 10.3359 0.667969ZM11.0026 3.50596C11.0026 3.68396 10.9333 3.85129 10.8073 3.97729L7.39331 7.3913C7.14131 7.6433 7.0026 7.97796 7.0026 8.33396V12.334L5.0026 10.834V8.33463C5.0026 7.97863 4.8639 7.64397 4.6119 7.39197L1.19792 3.97797C1.07258 3.85197 1.0026 3.68463 1.0026 3.50663V2.0013C1.0026 1.6333 1.30127 1.33464 1.66927 1.33464H10.3359C10.7039 1.33464 11.0026 1.6333 11.0026 2.0013V3.50596Z"
        fill={color}
      />
    </svg>
  );
};
SvgTesFilter.defaultProps = {
  size: 16,
  color: "currentColor"
};
export default SvgTesFilter;
