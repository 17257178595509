export const HOME = "/";
export const CONTACT = "/contact";
export const ABOUT = "/about";

export const SIGNUP = "/auth/register";
export const LOGIN = "/auth/login";

export const ABOUT_ME = "/profile/about_me";

// setting tabs routes
export const PROFILE = "/auth/users/:id";
export const CONFIRM_ACCOUNT = "/confirmAccount";

export const DASHBOARD = "/dashboard";
export const SCHOOL = "/schools";
export const PRINCIPALSANDVICEPRINCIPALS = "/principalsAndViceprincipals";
