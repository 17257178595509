// Auth routes
export const LOG_OUT = "auth/logout";
export const LOGIN = "auth/local/signin";
export const SIGNUP = "auth/local/signup";
export const LOGIN_SUCCESS_URL = "auth/login/success";

export const GET_USER = "/users";
export const FORGOT_PASSWORD = "/forgotPassword";
export const RESET_PASSWORD = "/resetPassword";
export const UPDATE_PASSWORD = "/users/:id/password-update";
/**
 * Settings
 */

export const TESCOM_USER_DATA = "TESCOM_USER_DATA";

export const PUBLIC_REQUEST_KEY = "public-request";

// Request Status

export const RESPONSE_SUCCESS = "SUCCESS";
export const RESPONSE_ERROR = "FAILURE";

export const UPLOAD_IMAGE = "upload/upload-image";
export const IMAGE_TAG = "user-image";
