import { Landing } from "components/layouts";
import HomeView from "./HomeView";

export const HomeContainer = () => {
  return (
    <Landing>
      <HomeView />
    </Landing>
  );
};
