import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
const SvgTesFlash = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 44 68"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M21.0626 54.2294C20.6917 54.0729 20.3834 53.7972 20.1865 53.4461C19.9896 53.0949 19.9152 52.6882 19.9751 52.29L23.2738 30.8119H14.5195C14.2419 30.8194 13.9664 30.763 13.7141 30.6471C13.4618 30.5312 13.2395 30.3589 13.0643 30.1435C12.8892 29.928 12.7659 29.6753 12.7039 29.4046C12.642 29.134 12.643 28.8527 12.707 28.5826L18.1445 5.02005C18.2401 4.61454 18.4726 4.25438 18.8027 4.00018C19.1328 3.74599 19.5405 3.61329 19.957 3.62443H38.0819C38.3527 3.62351 38.6203 3.68327 38.8649 3.79932C39.1096 3.91537 39.3251 4.08476 39.4957 4.29505C39.6687 4.50772 39.7912 4.75685 39.854 5.02371C39.9168 5.29056 39.9182 5.56819 39.8582 5.83568L36.7226 19.9369H45.332C45.6716 19.9362 46.0047 20.031 46.2931 20.2105C46.5816 20.39 46.8137 20.6469 46.9632 20.9519C47.0931 21.2447 47.1431 21.5667 47.108 21.8851C47.073 22.2035 46.9542 22.5069 46.7638 22.7644L23.2013 53.5769C23.0421 53.813 22.8295 54.0082 22.5807 54.1468C22.3319 54.2853 22.054 54.3633 21.7695 54.3744C21.5269 54.3699 21.2873 54.3207 21.0626 54.2294ZM32.1913 23.5619L35.8163 7.24943H21.407L16.8032 27.1869H27.5151L24.6332 45.8194L41.707 23.5619H32.1913Z"
        fill="currentColor"
      />
    </svg>
  );
};
SvgTesFlash.defaultProps = {
  size: 16,
  color: "currentColor"
};
export default SvgTesFlash;
